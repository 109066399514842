import IconPause from '@/assets/icons/icon-pause.svg?react';
import IconPlay from '@/assets/icons/icon-play.svg?react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

type Voice = {
  src: string | undefined;
  alt: string;
  audioUrl?: string;
};

type VoiceSelectionBlockProps = {
  voices: Voice[];
};

const VoiceSelectionBlock: React.FC<VoiceSelectionBlockProps> = ({
  voices,
}) => {
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const progressIntervalRef = useRef<number | null>(null);
  const [progressValues, setProgressValues] = useState<Record<string, number>>(
    {},
  );
  const [containerStates, setContainerStates] = useState<
    Record<string, boolean>
  >({});
  const audioPlayer = useGlobalAudioPlayer();
  const { getPosition, duration, playing, pause, load } = audioPlayer;

  // Memoize the progress update function
  const updateAudioProgress = useCallback(() => {
    if (!currentlyPlaying) return;

    const position = getPosition();

    if (duration && typeof position === 'number') {
      const progress = (position / duration) * 100;
      setProgressValues((prev) => ({
        ...prev,
        [currentlyPlaying]: progress,
      }));
    }
  }, [currentlyPlaying, getPosition, duration]);

  // Track audio progress
  useEffect(() => {
    if (!currentlyPlaying || !playing) {
      return;
    }

    // Update immediately and then every 100ms
    updateAudioProgress();
    const intervalId = setInterval(updateAudioProgress, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentlyPlaying, playing, updateAudioProgress]);

  const stopProgressSimulation = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
  }, []);

  const stopAllAudio = useCallback(() => {
    if (playing) {
      pause();
    }
    stopProgressSimulation();
    setCurrentlyPlaying(null);
    setContainerStates({});
    setProgressValues({});
  }, [playing, pause, stopProgressSimulation]);

  // Listen for external audio events
  useEffect(() => {
    const handleStopAllAudio = () => {
      if (currentlyPlaying) {
        stopAllAudio();
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('stopAllWavesurfers', handleStopAllAudio);
      window.addEventListener('audioStarted', handleStopAllAudio);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('stopAllWavesurfers', handleStopAllAudio);
        window.removeEventListener('audioStarted', handleStopAllAudio);
      }
    };
  }, [currentlyPlaying, stopAllAudio]);

  const updateProgress = (voiceName: string, progress: number) => {
    setProgressValues((prev) => ({ ...prev, [voiceName]: progress }));
  };

  const startProgressSimulation = (voiceName: string) => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    let progress = 0;
    progressIntervalRef.current = window.setInterval(() => {
      progress = (progress + 1) % 101;
      updateProgress(voiceName, progress);

      // Auto-stop when reaching 100%
      if (progress >= 100) {
        setTimeout(() => {
          if (currentlyPlaying === voiceName) {
            handlePlayPause(voiceName);
          }
        }, 500);
      }
    }, 100);
  };

  const playAudio = (voiceName: string) => {
    // First, ensure all audio is stopped
    stopAllAudio();

    // Stop any playing audio in other components
    if (typeof window !== 'undefined') {
      const stopEvent = new CustomEvent('stopAllWavesurfers');
      window.dispatchEvent(stopEvent);

      // Notify other components that we're starting audio
      const audioStartedEvent = new CustomEvent('audioStarted');
      window.dispatchEvent(audioStartedEvent);
    }

    const voice = voices.find((v) => v.alt === voiceName);
    if (voice?.audioUrl) {
      try {
        load(voice.audioUrl, {
          format: 'mp3',
          autoplay: true,
          html5: true,
          onload: () => {
            setCurrentlyPlaying(voiceName);
            setContainerStates((prev) => ({ ...prev, [voiceName]: true }));
          },
          onend: () => {
            setCurrentlyPlaying(null);
            setContainerStates((prev) => ({ ...prev, [voiceName]: false }));
            setProgressValues((prev) => ({ ...prev, [voiceName]: 0 }));
          },
        });
      } catch {
        // Fallback to simulation if audio loading fails
        startProgressSimulation(voiceName);
      }
    } else {
      // Fallback to simulation if no audio URL
      startProgressSimulation(voiceName);
    }
  };

  const handlePlayPause = (voiceName: string) => {
    if (currentlyPlaying === voiceName) {
      // Pause current voice
      stopAllAudio();
    } else {
      // Play new voice
      playAudio(voiceName);
    }
  };

  return (
    <div className="flex-grow">
      <div
        className="grid grid-cols-2 gap-3 sm:grid-cols-4 sm:gap-4 lg:grid-cols-2 xl:grid-cols-4"
        role="list"
        aria-label="Available voices"
      >
        {voices.map((voice) => (
          <div
            key={voice.alt}
            className="relative flex flex-col items-center rounded-lg bg-[rgba(255,255,255,0.10)] px-3 pt-3 sm:rounded-xl sm:px-4 sm:pt-4"
            role="listitem"
          >
            <span className="text-xs text-[rgba(3,49,75,0.70)] sm:text-sm">
              {voice.alt}
            </span>
            <img
              src={voice.src}
              alt={`Avatar for ${voice.alt} voice`}
              className="mx-auto mt-4 h-auto w-full min-w-[120px] max-w-[180px] rounded-lg sm:mt-6 lg:mt-8"
            />
            <div className="absolute -bottom-4 left-1/2 -translate-x-1/2">
              {/* Unified control container with absolutely positioned button */}
              <div
                className={`audio-control-container ${
                  containerStates[voice.alt] ? 'playing' : 'paused'
                }`}
              >
                {/* Absolutely positioned Play/Pause button */}
                <button
                  type="button"
                  className="audio-button"
                  onClick={() => {
                    handlePlayPause(voice.alt);
                  }}
                  aria-label={
                    containerStates[voice.alt]
                      ? `Pause ${voice.alt} voice sample`
                      : `Play ${voice.alt} voice sample`
                  }
                  aria-pressed={containerStates[voice.alt]}
                >
                  {containerStates[voice.alt] ? (
                    <IconPause className="text-[#03314B]" />
                  ) : (
                    <IconPlay className="text-[#03314B]" />
                  )}
                </button>

                {/* Progress bar - always rendered but conditionally visible */}
                <div
                  className={`progress-bar-container ${containerStates[voice.alt] ? 'visible' : ''}`}
                >
                  <div className="progress-bar-track">
                    <div
                      className="progress-bar-indicator"
                      style={{
                        width: `${String(progressValues[voice.alt] ?? 0)}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VoiceSelectionBlock;
